<template>
  <c-box w="100%" maxWidth="590px">
    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kondisi Asupan Cairan (gelas / hari)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Kondisi Asupan Cairan (gelas / hari)"
          height="62px"
          v-model="value.fluidIntakeCondition"
          :data-model="value.fluidIntakeCondition"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Standar Asupan Cairan (gelas / hari)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Standar Asupan Cairan (gelas / hari)"
          height="62px"
          v-model="value.fluidIntakeStandard"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kesimpulan Asupan Cairan
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.fluidIntakeConclusion"
          placeholder="Kesimpulan Asupan Cairan"
          height="62px"
        >
          <option value="adequate">Cukup</option>
          <option value="deficient">Kurang</option>
          <option value="excessive">Lebih</option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kondisi Aktifitas Fisik (menit / minggu)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Kondisi Aktifitas Fisik (menit / minggu)"
          height="62px"
          paddingRight="60px"
          v-model="value.physicalActivityCondition"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Standar Aktifitas Fisik (menit / minggu)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Standar Aktifitas Fisik (menit / minggu)"
          height="62px"
          paddingRight="60px"
          v-model="value.physicalActivityStandard"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kesimpulan Aktifitas Fisik
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.physicalActivityConclusion"
          placeholder="Kesimpulan Aktifitas Fisik"
          height="62px"
        >
          <option value="adequate">Cukup</option>
          <option value="deficient">Kurang</option>
          <option value="excessive">Lebih</option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kondisi Lingkar Pinggang (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Kondisi Lingkar Pinggang (cm)"
          height="62px"
          paddingRight="70px"
          v-model="value.waistSize"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Minimum Standar Lingkar Pinggang (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Minimum Standar Lingkar Pinggang (cm)"
          height="62px"
          paddingRight="90px"
          v-model="value.minimumStandardWaistSize"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Maximum Standar Lingkar Pinggang (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Maximum Standar Lingkar Pinggang (cm)"
          height="62px"
          paddingRight="90px"
          v-model="value.maximumStandardWaistSize"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control is-required marginBottom="80px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kesimpulan Lingkar Pinggang
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.waistSizeConclusion"
          placeholder="Kesimpulan Lingkar Pinggang"
          height="62px"
        >
          <option value="adequate">Cukup</option>
          <option value="deficient">Kurang</option>
          <option value="excessive">Lebih</option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <c-form-control is-required marginBottom="30px" display="flex">
      <c-button
        backgroundColor="superLightGray.900"
        px="26px"
        fontSize="18px"
        fontWeight="500"
        py="26px"
        borderRadius="58px"
        width="100%"
        color="darkGray.900"
        marginRight="20px"
        marginBottom="20px"
        as="router-link"
        :to="{ name: 'nutri.profile-gizi' }"
      >
        Batal
      </c-button>
      <c-button
        backgroundColor="brand.900"
        px="26px"
        fontSize="18px"
        fontWeight="500"
        py="26px"
        borderRadius="58px"
        width="100%"
        color="white"
        @click="$emit('submit')"
      >
        Simpan
      </c-button>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "EditBodyCompositionForm",
  props: ["value"],
};
</script>
